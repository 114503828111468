import './test.css'
import { Data } from './Data'
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

const Test = () => {
  return (
    <section data-aos="fade-left" className="section test container section" id='test'>
        <h2 className="section__title">My Clients Say</h2>
        <span className="section__subtitle">Testimonial</span>

        <Swiper className="test__container"
        loop={true}
        grabCursor={true}
        spaceBetween={24}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        }}
        modules={[Pagination]}
        
        >
            {Data.map(({id, image, title, description}) =>{
                return(
                    <SwiperSlide className='test__card' key={id}>
                        <img src={image} alt="" className="test__img" />

                        <h3 className="test__name">{title}</h3>
                        <p className="test__description">{description}</p>
                    </SwiperSlide>
                )
            } )}

        </Swiper>
    </section>
  )
}

export default Test