import Blog from "../../assets/myblog.png";
import MobileArt from "../../assets/mobileart.png";
import Parallex from "../../assets/parallex__website.jpg";
import CommingSoon from "../../assets/coming soon.jpg";
import FacebookClone from "../../assets/facebook clone.jpg";
import RealEstate from "../../assets/real-estate-web.png";

export const projectsData = [
  {
    id: 1,
    image: Blog,
    title: "My Blog",
    category: "web",
    demoLink: "#",
  },
  {
    id: 2,
    image: MobileArt,
    title: "Mobile Art",
    category: "web",
    demoLink: "https://mobileart.netlify.app/",
  },
  {
    id: 3,
    image: Parallex,
    title: "Parallax Web Design",
    category: "design",
    demoLink: "https://tskparallex.netlify.app/",
  },
  {
    id: 4,
    image: FacebookClone,
    title: "Facebook Clone",
    category: "web",
    demoLink: "https://fakebook2009.netlify.app/",
  },
  {
    id: 5,
    image: CommingSoon,
    title: "App Movil",
    category: "app",
    demoLink: "#",
  },
  {
    id: 6,
    image: RealEstate,
    title: "Real Estate WebApp",
    category: "web",
    demoLink: "https://tsk-real-estate-web.pages.dev/",
  },
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "design",
  },
];
