import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Skills from './Components/Skills/Skills';
import Services from './Components/Services/Services';
import Work from './Components/Work/Work';
import Test from './Components/Testimonials/Test';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import ScrollUp from './Components/Scrollup/ScrollUp';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


function App() {
  return (
    <div className="App">
      <Header/>
      <main className="main">
        <Home/>
        <About/>
        <Skills/>
        <Services/>
        <Work/>
        <Test/>
        <Contact/>
      </main>

      <Footer/>
      <ScrollUp/>
    </div>
  );
}

export default App;
