import Image1 from '../../assets/testimonial1.png'
import Image2 from '../../assets/testimonial2.png'
import Image3 from '../../assets/testimonial3.png'
import Image5 from '../../assets/test5.jpg'

export const Data = [
    {
      id: 1,
      image: Image1,
      title: "Jhon Doe",
      description:
        "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
      id: 2,
      image: Image2,
      title: "Emma Davis",
      description:
      "Innovative design and seamless navigation. Great job capturing our brand essence!",
    },
    {
      id: 3,
      image: Image3,
      title: "Sara Cill",
      description:
        "Responsive and user-friendly.A really good job, all aspects of the project with good results.",
    },
   
    {
      id: 4,
      image: Image5,
      title: "Olivia Taylor",
      description:
        "Clean aesthetics, but some links are inconsistent. Quick fixes would perfect,A really good job!",
    },

  ];
